import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import { jsPDF } from "jspdf";
import 'react-toastify/dist/ReactToastify.css';
import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { motion } from "framer-motion";
import html2canvas from 'html2canvas';
import "swiper/swiper.min.css";
import "./App.css";
import Casyum from './Images/IMG_5023.PNG'
import SRM from './Images/SRM RMP Logo (1).png'
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faPlus,
  faNoteSticky,
  faXmark,
  faCalendarDays,
  faClipboard,
  faMinus,
  faNotesMedical,
  faLocationDot,
  faEdit,
  faCheckSquare,
  faArrowTrendUp,
  faGear,
  faBars,
  faArrowTrendDown,
  faMoneyBillTrendUp,
  faRightFromBracket,
  faArrowsRotate,
  faMapLocationDot,
  faClockFour,
  faInr,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { faClock } from "@fortawesome/free-regular-svg-icons";
import ReactDOM from "react-dom";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Slide from "./components/landing";
import TextField from "@mui/material/TextField";
import { Input } from "@material-tailwind/react";
// import Navbar from "./components/Navbar";
import Achivements from "./components/achievements";
import Landing from "./components/landing";
import Landing2 from "./components/landing2";
// import Services from "./components/Sevices";
// import Price from "./components/price";
// import Chooseus from "./choose us/Choose";
import QRCode from "react-qr-code";
// import { get, getDatabase, ref, set,child, remove, update,push } from "firebase/database";
// import { initializeApp} from "firebase/app";
// import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { maxWidth, width } from "@mui/system";
import { Button } from "@mui/material";
import CountdownTimer from "./components/Time";
import EventSection from "./components/Events";
import PricingSection from "./components/Pricing";
import ScrollingCards from "./components/ScrollingCards";
import GlassmorphicFooter from "./components/Footer";


SwiperCore.use([EffectCoverflow, Pagination]);


const App = () => {
 return (
  <div>
 <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/> 
    <div className="boss"  >
    <header className="hidden md:flex fixed top-0 rounded-xl p-10 pl-0 md:p-5 left-0 m-10 right-0  pr-5 z-50 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg border-b border-white border-opacity-10 flex justify-between items-center ">
      <div></div>
  <img src={SRM} alt="Left Logo" className="w-[150px] hidden " />
  <img src={Casyum} alt="Right Logo" className="w-[150px] md:w-[120px] absolute  hidden md:block" />
</header>
  <Landing ></Landing>

      <Landing2 className="child"></Landing2>
{/* <EventSection></EventSection> */}
   {/* <PricingSection></PricingSection> */}
   <ScrollingCards></ScrollingCards>
   <GlassmorphicFooter></GlassmorphicFooter>
</div>
{/* <h1 className="text-white absolute text-[30px] top-[50%] text-center left-[50%]">
Pushing updates kindly comeback in a bit.</h1> */}
</div>
   


    
 
    


   
  );
};

export default App;


