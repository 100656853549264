import React from "react";

const Landing2 = () => {
  return (
    <div className="w-full h-full bg-black pt-10 pb-[100px] ">
      <div className="landing2">
        <div className="bg-text-container">
    
          <div className="animate-text">
            <span className="spaner">Vibe On!&nbsp;</span>
            <span className="spaner">Vibe On!&nbsp;</span>
          </div>
          <div className="animate-text left">
            <span className="spaner">Vibe On!&nbsp;</span>
            <span className="spaner">Vibe On!&nbsp;</span>
          </div>
        </div>

        {/* Main Text Content */}
        <div className="container mx-auto px-4 md:px-12 lg:px-16 overflow-hidden h-full ">
          <div className="col">
            <h1 className="text-[35px] sm:text-[40px] lg:text-[50px] text-white font-semibold mb-6">
            FEEL THE RHYTHM OF AURA
            </h1>
            <p className="text-[18px] sm:text-[20px] lg:text-[25px] text-gray-300 leading-relaxed">
            The concert will feature various performances from Famous Music Artists and
bands, creating an atmosphere of unity and joy. We aim to promote community
engagement,support local and foster a love for music among attendees.

            <br></br>
            <br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing2;
